<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-card flat max-width="1100" width="100%" class="mb-5">
        <v-toolbar flat class="mb-3">
          <h1 class="headline black--text">Messaging</h1>
          <v-spacer />
          <v-btn color="primary" depressed to="/patients/messaging/upload">New Messaging</v-btn>
        </v-toolbar>
        <v-row>
          <v-col cols="12">
            <v-textarea v-model="message" outlined :label="$t('hiring.reason_of_consultation')" />
          </v-col>
          <v-col cols="12">
            <v-alert color="grey-darken-1" outlined>
              <d-upload-file v-if="!document.selected" :document="document" :dense="true" :convert-auto-base64="true" />
              <div v-else>
                <div class="mb-5">
                  <v-icon size="140" color="grey lighten-2">{{ document.typeIcon }}</v-icon>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12">
            <btn @click="send">enviar</btn>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import Alerts from '@/mixins/Alerts';
import { getHeader, getMessagesUrl, urlPatient } from '../../config/config';
import DUploadFile from '../../components/DUploadFile.vue';

export default {
  components: { DUploadFile },
  title: 'Messaging',
  mixins: [Alerts],
  data() {
    return {
      isLoading: false,
      overlay: false,
      messagingId: vm.$route.params.messagingId,
      tab: 2,
      currentPage: 1,
      perPage: 24,
      totalPages: null,
      totalResults: null,
      messages: [],
      message: '',
      document: {
        selected: false,
        uploaded: false,
        selecting: false,
        file: '',
        base64: '',
        typeIcon: 'mdi-text-box-check-outline',
        sizeMax: 20,
        professional: 0,
      },
    };
  },
  mounted() {
    this.loadMessages();
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
      this.loadMessages();
    },
    loadMessages() {
      this.isLoading = true;
      this.$http
        .get(urlPatient(getMessagesUrl, 0, 0) + '/' + this.messagingId, { headers: getHeader() })
        .then(() => {
          // res.body.data;
          // this.totalPages = Math.ceil(this.totalResults / this.perPage);
          this.$scrollTo('#app');
        })
        .catch(err => {
          this.$log.error(err);
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    send() {
      const param = {
        message: this.message,
        attachedFile: {
          name: this.document.file.name,
          content: this.document.base64,
        },
      };
      this.$http
        .post(urlPatient(getMessagesUrl, 0, 0) + '/' + this.messagingId, param, { headers: getHeader() })
        .then(() => {
          // res.body.data;
          // this.totalPages = Math.ceil(this.totalResults / this.perPage);
          this.$scrollTo('#app');
        })
        .catch(err => {
          this.$log.error(err);
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
