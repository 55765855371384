<template>
  <v-container
    :class="dense ? 'd-upload-file-dense' : 'd-upload-file'"
    @dragover="fileDragHover"
    @dragleave="fileDragHover"
    @drop="fileSelectHandler"
  >
    <div :class="!dense ? 'mb-5' : ''">
      <v-icon v-if="document.uploaded" size="140" color="green lighten-1">mdi-check</v-icon>
      <v-icon v-else size="140" color="grey lighten-4">{{
        document.selecting ? 'mdi-inbox-arrow-down' : 'mdi-upload'
      }}</v-icon>
    </div>
    <div :class="!dense ? 'mb-5' : ''">
      {{ document.uploaded ? $t('documentos.subido_correctamente') : $t('documents.drag_and_drop') }}
    </div>
    <div v-show="!document.uploaded" :class="!dense ? 'mb-5' : ''">{{ $t('or') }}</div>
    <v-btn class="mb-5 primary--text" depressed @click="browseFile">
      {{ document.uploaded ? $t('documents.upload_new') : $t('documents.browse_file') }}
    </v-btn>
    <div v-show="!document.uploaded" :class="!dense ? 'mb-5 grey--text' : 'grey--text'">
      {{ $t('documents.supported_files') }}
      <br />{{ $t('documents.size_limited', { num: document.sizeMax }) }} <br />XLS, PDF, JPG, PNG
    </div>
    <input
      v-show="false"
      id="newfile"
      ref="filenew"
      type="file"
      accept="application/vnd.ms-excel,text/plain, application/pdf, image/*, .pdf, video/mp4,video/x-m4v,video/avi"
      @change="onFileUpdated"
    />
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'DUploadFile',

  props: {
    document: {
      type: Object,
      default: () => ({
        selected: false,
        uploaded: false,
        selecting: false,
        file: '',
        base64: '',
        typeIcon: 'mdi-text-box-check-outline',
        sizeMax: 20,
        professional: 0,
      }),
    },
    dense: {
      type: Boolean,
      default: false,
    },
    convertAutoBase64: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filetypes: [
        'application/vnd.ms-excel',
        'text/plain',
        'application/pdf',
        'image/jpg',
        'image/jpeg',
        'image/png',
        'video/mp4',
        'video/x-m4v',
        'video/avi',
      ],
    };
  },
  computed: {},
  methods: {
    ...mapActions('layout', ['showAlert']),

    browseFile() {
      this.document.uploaded = false;
      if (!this.isLoading) {
        if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
          document.getElementById('newfile').setAttribute('accept', '*.*');
        }
        this.$refs.filenew.click();
      }
    },

    isValid() {
      let res = true;
      if (!this.filetypes.includes(this.document.file.type)) {
        this.showAlert({
          color: 'error',
          icon: 'mdi-alert-circle',
          message: this.$t('errors.file_uploaded_invalid'),
        });
        return false;
      }
      if (this.document.file.size > this.document.sizeMax * 1000000) {
        this.showAlert({
          color: 'error',
          icon: 'mdi-alert-circle',
          message: this.$t('errors.file_max_upload', { tam: this.document.sizeMax }),
        });
        res = false;
      }
      return res;
    },

    onFileUpdated(e) {
      this.document.selected = true;
      this.document.file = e.target.files[0];
      this.document.selected = typeof this.document.file !== 'undefined' && this.isValid();
      if (this.convertAutoBase64) {
        this.uploadDocumentBase64(this.document.file);
      }
    },

    uploadDocumentBase64(file) {
      const reader = new FileReader();
      reader.onload = e => {
        this.document.base64 = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    fileDragHover(e) {
      e.stopPropagation();
      e.preventDefault();
      this.document.selecting = e.type === 'dragover';
    },

    fileSelectHandler(e) {
      this.fileDragHover(e);
      const files = e.target.files || e.dataTransfer.files;
      this.document.file = files[0];
      this.document.selected = typeof this.document.file !== 'undefined' && this.isValid();
      if (this.convertAutoBase64) {
        this.uploadDocumentBase64(this.document.file);
      }
    },
  },
};
</script>

<style>
.d-upload-file-dense {
  padding: 0px;
}
</style>
